import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import Text from '../Text/Text'
import { SCREEN_SIZE } from '../../lib/Media'
import {
  FOOTER_NAVIGATION_B2C,
  FOOTER_NAVIGATION_B2B,
  LINKS,
} from './../../lib/navigation'
import SvgYoutube from '../icons/svg/youtube.svg'
import SvgFacebook from '../icons/svg/facebook.svg'
import SvgInstagram from '../icons/svg/instagram.svg'
import BarcodeImg from './../../static/Barcode/barcode.svg'
import SvgGooglePlayStore from '../icons/svg/goole-play-store.svg'
import SvgApplePlayStore from '../icons/svg/apple-store.svg'
import HuaweiStoreIcon from './../../static/huawei/huawei-icon.png'
import { EventTrackingConsumer } from '../EventTrackingProvider/EventTrackingProvider'
import { GTM_ENV_VAR as GEV } from '../../lib/eventTrackerFunction'
import { setObserver } from '../../lib/impressionsTracker'
import { IdleQueue } from '../../lib/idlize/IdleQueue'
import { getUserType, setUserType } from '../../utils/userType'
import { AccountConsumer } from '../AccountProvider'
import { AMP_EVENT_NAME } from '../../lib/amplitude'
import { Router } from '../../routes'
import { getExpConfig } from '../../utils/configService'
import { SPLIT_FEATURES } from '../../utils/constants'
import {
  SvgVisa,
  SvgApplePay,
  SvgAmex,
  SvgMasterCard,
  SvgGooglePay,
} from '@ntuctech/devex-tangram/PaymentIcon'
import { clearDySession } from '../../utils/DyUtils'

const queue = new IdleQueue()

const FooterContainer = styled.div`
  background-color: #fff;
  border-top: 1px solid #eaeaea;
  margin: 0 auto;
`

const ContainerForLinks = styled.div`
  display: flex;
  flex-direction: column;
  color: #1557bf;
  width: 100%;
  > div:not(:last-child) {
    margin-bottom: 1rem;
  }
  ${SCREEN_SIZE.From.Tablet} {
    width: 25%;
  }
  ${SCREEN_SIZE.From.Desktop} {
    width: 20%;
  }
`

const ContainerForBarCode = styled(ContainerForLinks)`
  ${SCREEN_SIZE.From.Tablet} {
    display: none;
  }
  ${SCREEN_SIZE.From.Desktop} {
    display: block;
  }
`

const StyledA = styled.a`
  text-decoration: none;
  color: #1557bf;
  padding: 0.4rem 0;
  ${SCREEN_SIZE.From.Tablet} {
    padding: 0.2rem 0;
  }
`

const InfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  > span:not(:last-child) {
    padding-bottom: 0.5rem;
  }
  padding-top: 1rem;
`

const StoreDiv = styled.div`
  display: flex;
  flex-direction: column;
  > span:not(:last-child) {
    padding-bottom: 1rem;
  }
`

const PaymentsDiv = styled.div`
  display: flex;
  margin-bottom: 1rem;
  > svg {
    width: auto;
    height: 2rem;
  }
  > span {
    display: block;
    height: 2rem;
  }
  > svg:not(:last-child) {
    margin-right: 0.5rem;
  }
  > div:not(:last-child) {
    margin-bottom: 1rem;
  }
  flex-direction: row;
`
const SocialMediaDiv = styled.div`
  margin-bottom: 1rem;
  > a:not(:last-child) {
    margin-right: 0.75rem;
  }
`

const AppStoreDiv = styled.div`
  > svg {
    width: 9.875rem;
    height: 3rem;
  }
  > svg:not(:last-child) {
    margin-right: 0.75rem;
  }
  ${SCREEN_SIZE.From.Desktop} {
    display: none;
  }
`

const Heading = styled.div`
  color: #696969;
  padding-bottom: 0.5rem;
`

const BarCodeDiv = styled.div`
  display: none;
  ${SCREEN_SIZE.From.Desktop} {
    display: block;
    padding: 0.25rem 0 0.5rem 0;
  }
`

const StyledText = styled(Text)`
  cursor: pointer;
  display: span;
`

const Splitter = styled.div`
  margin: 0.625rem 0.625rem 0.625rem 0;
  border: 0.5px solid #ccc;
`
const FooterWrapper = styled.div`
  display: flex;
  position: relative;
  bottom: 0;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;

  ${SCREEN_SIZE.From.Tablet} {
    flex-direction: unset;
    padding: 1.5rem 2rem;
  }

  ${SCREEN_SIZE.From.Desktop} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  ${SCREEN_SIZE.From.UhdL} {
    max-width: 100rem;
    margin: 0 auto;
  }
`

const StyledLink = styled.a.attrs(props => ({
  'aria-label': props.ariaLabel,
}))`
  text-decoration: none;
`

const BarcodeImage = styled.img`
  height: 9rem;
  width: 9rem;
`

const FooterItems = ({ items, track, isLoggedIn }) => {
  const isGiftingDomainEnabled =
    getExpConfig(SPLIT_FEATURES.GIFT_ENTRY_POINT)?.treatment === 'on'
  return items.map(item => {
    if (!isGiftingDomainEnabled && item.url === LINKS.sendEGiftVoucher.url) {
      return null
    }
    const info = (
      <StyledA
        target={item.isNewTab ? '_blank' : ''}
        rel="noopener noreferrer"
        href={!item.isAbsoluteUrl ? null : item.url}
        onClick={e => {
          if (item.label === 'Weekly promotions') {
            e.preventDefault()
            Router.pushRoute('/weekly-promotions')
          }
          if (item.label === LINKS.shopForBusiness.label) {
            setUserType('B2B', isLoggedIn, true, null)
            clearDySession()
          } else if (item.label === LINKS.shopForPersonal.label) {
            clearDySession()
            setUserType('B2C', isLoggedIn, true, null)
          }
          track({
            [GEV.EVENT_CAT]: 'PromotionFunnel',
            [GEV.EVENT_ACTION]: 'PromotionClick',
            [GEV.EVENT_LABEL]: `Footer_Text Link - ${item.label}`,
            [GEV.EVENT]: 'promotionClick',
          })
          if (item.label === LINKS.sendEGiftVoucher.label) {
            track({
              [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.EGIFTING_MAIN_CLICKED,
              [GEV.AMPLITUDE_CTA_LOCATION]: 'home grocery-online',
              [GEV.AMPLITUDE_CTA_SUBLOCATION]: 'navigation more footer',
              [GEV.EVENT_CAT]: 'egifting voucher',
              [GEV.EVENT_ACTION]: 'click',
              [GEV.EVENT_LABEL]: 'gifting_footer',
              [GEV.EVENT]: 'hm_push_event',
              [GEV.ASSET_TYPE]: 'e-gifting',
            })
          }
        }}
      >
        <StyledText size="large">{item.label}</StyledText>
      </StyledA>
    )
    if (!item.isAbsoluteUrl) {
      return (
        <Link href={item.url} key={item.label} passHref legacyBehavior>
          {info}
        </Link>
      )
    } else {
      return info
    }
  })
}

class PageFooterComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isB2BUser: false,
    }
  }

  componentDidMount() {
    const isB2BUser = getUserType() === 'B2B'
    this.setState({ isB2BUser })
    queue.pushTask(() => {
      if (!this.footerRef) {
        return
      }

      const observerCallback = entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.props.track({
              [GEV.EVENT_CAT]: 'PromotionFunnel',
              [GEV.EVENT_ACTION]: 'PromotionImpressions',
              [GEV.EVENT_LABEL]: 'Footer_Text Link',
              [GEV.EVENT]: 'promoImpression',
            })
          }
        })
      }

      setObserver({
        elementRef: this.footerRef,
        observerOptions: {
          root: null,
          rootMargin: '0px',
          threshold: 0.3,
        },
        callback: observerCallback,
      })
    })
  }

  render() {
    const { track, isLoggedIn } = this.props
    const { isB2BUser } = this.state
    const NAVIGATION = isB2BUser ? FOOTER_NAVIGATION_B2B : FOOTER_NAVIGATION_B2C
    return (
      <FooterContainer
        id="page-footer-container"
        ref={node => (this.footerRef = node)}
      >
        <FooterWrapper>
          <ContainerForLinks>
            <InfoDiv>
              <FooterItems
                items={NAVIGATION.firstColumn}
                track={track}
                isLoggedIn={isLoggedIn}
              />
            </InfoDiv>
          </ContainerForLinks>
          {!isB2BUser && (
            <ContainerForLinks>
              <InfoDiv>
                <Heading>
                  <Text size="small" weight="black" color="#696969">
                    MEMBERSHIPS
                  </Text>
                </Heading>
                <FooterItems
                  items={NAVIGATION.secondColumn}
                  track={track}
                  isLoggedIn={isLoggedIn}
                />
              </InfoDiv>
            </ContainerForLinks>
          )}
          <ContainerForLinks>
            <InfoDiv>
              <Heading>
                <Text size="small" weight="black" color="#696969">
                  OUR STORES
                </Text>
              </Heading>
              <StoreDiv>
                <FooterItems
                  items={NAVIGATION.ourStores}
                  track={track}
                  isLoggedIn={isLoggedIn}
                />
              </StoreDiv>
              {!isB2BUser && (
                <>
                  <Splitter />
                  <StoreDiv>
                    <FooterItems
                      items={NAVIGATION.newTermsAndPolicy}
                      track={track}
                      isLoggedIn={isLoggedIn}
                    />
                  </StoreDiv>
                </>
              )}
            </InfoDiv>
          </ContainerForLinks>
          {isB2BUser && (
            <ContainerForLinks>
              <InfoDiv>
                <FooterItems
                  items={NAVIGATION.newTermsAndPolicy}
                  track={track}
                  isLoggedIn={isLoggedIn}
                />
              </InfoDiv>
            </ContainerForLinks>
          )}

          <ContainerForLinks>
            <InfoDiv>
              <Heading>
                <Text size="small" weight="black" color="#696969">
                  SECURE PAYMENT METHODS
                </Text>
              </Heading>
              <PaymentsDiv>
                <SvgAmex />
                <SvgMasterCard />
                <SvgVisa />
                <SvgApplePay />
                <SvgGooglePay />
              </PaymentsDiv>
              <Heading>
                <Text size="small" weight="black" color="#696969">
                  FOLLOW US ON
                </Text>
              </Heading>
              <SocialMediaDiv>
                <StyledLink
                  href="https://www.facebook.com/thatsmyfairprice"
                  ariaLabel="facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={SvgFacebook} loading="lazy" alt="facebook" />
                </StyledLink>
                <StyledLink
                  href="https://www.youtube.com/user/NTUCFairPriceSG"
                  ariaLabel="youtube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={SvgYoutube} alt="youtube" loading="lazy" />
                </StyledLink>
                <StyledLink
                  href="https://www.instagram.com/fairpricesg/"
                  ariaLabel="instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={SvgInstagram} loading="lazy" alt="instagram" />
                </StyledLink>
              </SocialMediaDiv>
              <AppStoreDiv>
                <Heading>
                  <Text size="small" weight="black" color="#696969">
                    DOWNLOAD OUR APP
                  </Text>
                </Heading>
                <a href="https://apps.apple.com/sg/app/fairprice-fast-reliable/id1012365118">
                  <img
                    src={SvgApplePlayStore}
                    loading="lazy"
                    alt="Apple Store"
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.fairprice.mcomapp&hl=en_SG&gl=US">
                  <img
                    src={SvgGooglePlayStore}
                    loading="lazy"
                    alt="Google Play store"
                  />
                </a>
                <a href="https://appgallery.huawei.com/#/app/C101292929?channelId=email_referral&id=a0ee9e4a69c044c2914121ee9dead4d3&s=D3880EF47AE07200745CDF9386D5ADD353F62681B56170113297547FF6444D8A&detailType=0&v=">
                  <img
                    width="158px"
                    height="48px"
                    src={HuaweiStoreIcon}
                    loading="lazy"
                    alt="Huawei store"
                  />
                </a>
              </AppStoreDiv>
            </InfoDiv>
          </ContainerForLinks>
          <ContainerForBarCode>
            <InfoDiv>
              <BarCodeDiv>
                <Heading>
                  <Text size="small" weight="black" color="#696969">
                    DOWNLOAD OUR APP
                  </Text>
                </Heading>
                <BarcodeImage alt="Barcode" loading="lazy" src={BarcodeImg} />
              </BarCodeDiv>
            </InfoDiv>
          </ContainerForBarCode>
        </FooterWrapper>
      </FooterContainer>
    )
  }
}

const PageFooter = props => (
  <AccountConsumer>
    {({ isLoggedIn }) => (
      <EventTrackingConsumer>
        {({ track }) => (
          <PageFooterComponent
            {...props}
            track={track}
            isLoggedIn={isLoggedIn}
          />
        )}
      </EventTrackingConsumer>
    )}
  </AccountConsumer>
)

export default PageFooter
